import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './RelatedProducts.css';
import Slider from "react-slick";
import redsaree1 from '../../Assets/images/redsaree1.jpg';
import redsaree2 from '../../Assets/images/redsaree2.jpg';
import violet1 from '../../Assets/images/violet1.jpg';
import violet2 from '../../Assets/images/violet2.jpg';
import magenta1 from '../../Assets/images/magenta1.jpg';
import magenta2 from '../../Assets/images/magenta2.jpg';
import black1 from '../../Assets/images/black1.jpg';
import black2 from '../../Assets/images/black2.jpg';
import cart2 from '../../Assets/images/cart-gold.svg';
import leaf from '../../Assets/images/goldenleaf.png';
import axios from "axios";
import api from "../../API/api";


const RelatedProducts = ({ category }) => {
    const [relatedProducts, setRelatedProducts] = useState([])
    const navigate = useNavigate()


    useEffect(() => {
        api.get(`/products/list?category=${category}`)
        .then((response) => setRelatedProducts(response.data.products))
        .catch((error) => console.error('Error fetching products', error.message)
        )
    },[category])

    const handleAddToCart = async (id) => {
        try {
            const quantity = 1
            const response = await api.post('/cart/add', { productId:id, quantity });
            alert(response.data.message); // Notify user of success
        } catch (error) {
            console.error('Error adding to cart:', error);
            alert(error.response?.data?.error || 'Failed to add product to cart.');
        }
    };
    const viewSingleProduct = async (id) => {
        navigate(`/productDetails/${id}`)
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
      };
    return(
        <div className="related-sec">
            {/* <span className="leaf">
                <img src={leaf} alt="" />
            </span> */}
            <div className="wrapper">
                <h2>Related Products</h2>
                <div className='related-row'>
                    <Slider {...settings}>
                        {relatedProducts.map((product, index) => {
                            const imageUrl = 
                            product.images && product.images.length > 0
                            ? `https://backend.whitefeatherfashions.com/${product.images[0].replace(/\\/g, '/')}`
                            : 'https://via.placeholder.com/150';
                            
                            return (
                            <div className='related-box' key={index} >
                                <div className='related-img'>
                                    <img src={imageUrl} alt={product.name} onClick={() => viewSingleProduct(product._id)} />
                                    <span className='hover-img'>
                                        <img src={product.image2} alt={`${product.name} alternate`} />
                                    </span>
                                </div>
                                <h3 onClick={() => viewSingleProduct(product._id)}>{product.name}</h3>
                                <h4 onClick={() => viewSingleProduct(product._id)}>AED {product.price}</h4>
                                <button className='add-btn global-btn' onClick={() => {handleAddToCart(product._id)}}>
                                    Add To Cart
                                    <span className='icon'>
                                        <img src={cart2} alt="Cart Icon" />
                                    </span>
                                </button>
                            </div>
                        )})}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default RelatedProducts