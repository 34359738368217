import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import ContactSec from '../../Components/ContactSec/ContactSec';
import Footer from '../../Components/FooterSection/FooterSection';

function Contact() {
  return (
    <>
        <Navbar/>
        <ContactSec/>
        <Footer/>
    </>
  );
}

export default Contact;