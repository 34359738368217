// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatsappdiv{
  position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    /* WhatsApp Green */
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    z-index: 1000;
  	width:60px;
  	height:60px;
  	display:flex;
  	align-items:center;
  	justify-content:center;
}
.whatsappdiv a{
  display:inline-block;
  width:40px;
  height:40px;
  object-fit:cover;
}`, "",{"version":3,"sources":["webpack://./src/Components/whatsAppComponent/whatsAppComponent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;IACb,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,2CAA2C;IAC3C,+BAA+B;IAC/B,aAAa;GACd,UAAU;GACV,WAAW;GACX,YAAY;GACZ,kBAAkB;GAClB,sBAAsB;AACzB;AACA;EACE,oBAAoB;EACpB,UAAU;EACV,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".whatsappdiv{\n  position: fixed;\n    bottom: 20px;\n    right: 20px;\n    background-color: #25d366;\n    /* WhatsApp Green */\n    border-radius: 50%;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s ease;\n    z-index: 1000;\n  \twidth:60px;\n  \theight:60px;\n  \tdisplay:flex;\n  \talign-items:center;\n  \tjustify-content:center;\n}\n.whatsappdiv a{\n  display:inline-block;\n  width:40px;\n  height:40px;\n  object-fit:cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
