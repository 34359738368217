import React, { useState, useEffect } from "react";
import api from "../../API/api";
import "./AdminProductBanner.css"; // Import the CSS file

const UpdateProductBanner = () => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchHomeBanner = async () => {
      try {
        const response = await api.get("/admin/productbanner");
        const { title, image} = response.data;
        setTitle(title || "");
        setPreview(image || null);
      } catch (error) {
        console.error("Error fetching home banner:", error);
      }
    };

    fetchHomeBanner();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    if (image) formData.append("image", image);

    try {
        
        
      const response = await api.put("/admin/productbanner", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error("Error updating product banner:", error);
      setMessage("Failed to update product banner.");
    }
  };

  const handleImageChange = (file) => {
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="update-banner-container">
      <h2 className="update-banner-heading">Update Product Banner</h2>

      <form onSubmit={handleSubmit} className="update-banner-form">
        <label className="update-banner-label">Title</label>
        <input
          type="text"
          placeholder="Enter title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="update-banner-input"
        />

        <label className="update-banner-label">Image</label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e.target.files[0])}
          className="update-banner-input"
        />

        {preview && <img src={preview} alt="Preview" className="update-banner-preview" />}

        <button type="submit" className="update-banner-button">
          Update Banner
        </button>

        {message && (
          <div
            className={`update-banner-message ${
              message.includes("successfully") ? "success" : "error"
            }`}
          >
            {message}
          </div>
        )}
      </form>
    </div>
  );
};

export default UpdateProductBanner;
