import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Products from './Pages/Products/Products';
import ProductDetail from './Pages/ProdutDetail/ProdutDetail';
import RegisterPage from './Pages/RegisterPage/registerPage';
import AdminLogin from './Components/AdminLogin/adminLogin';
import AdminDashboard from './Components/AdminDashboard/adminDashboard';
import AdminRegister from './Components/AdminRegister/adminRegister';
import EditProduct from './Components/EditProduct/editProduct';
import Profile from './Pages/Profile/ProfilePage'
import ShopPage from './Pages/Shop/ShopPage';
import UserLoggedIn from './Components/userLoggedIn';
import UserLoggedOut from './Components/userLoggedOut';
import WhatsAppIcon from './Components/whatsAppComponent/whatsAppComponent';
import ScrollToTop from './Components/ScrollTop/scrollTop';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  <BrowserRouter>

    <ScrollToTop />

  <WhatsAppIcon />
  
    <Routes>

      <Route path="/index" element={<App />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/product" element={<Products />} />
      <Route path="/product-detail" element={<ProductDetail />} />


      <Route path='/profile' element={<Profile />} />
      <Route path='/productDetails/:id' element={<ProductDetail />} />
      <Route path='/shop' element={<ShopPage />} />







      <Route path="/register-login" element={<RegisterPage />} />





      {/* <Route path='/admin-register' element={<AdminRegister />} /> */}
      <Route path='/admin-login' element={<AdminLogin />} />
      <Route path='/admin-dashboard' element={<AdminDashboard />} />
      <Route path='/edit-product/:id' element={<EditProduct />} />




      <Route path="*" element={<Navigate to="/index" replace />} />
    </Routes>




  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
