// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-banner-form {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin: 0 auto;
  }
  
  .product-banner-form h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .product-banner-form .section {
    margin-bottom: 20px;
  }
  
  .product-banner-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .product-banner-form input[type="text"],
  .product-banner-form textarea,
  .product-banner-form input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .product-banner-form textarea {
    height: 100px;
    resize: vertical;
  }
  
  .product-banner-form .submit-button {
    background-color: #90ee90; /* Light green */
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
  }
  
  .product-banner-form .submit-button:hover {
    background-color: #7ecf7e; /* Darker green on hover */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/Components/AdminAboutBanner/AboutBannerForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;;;IAGE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,yBAAyB,EAAE,gBAAgB;IAC3C,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,yBAAyB,EAAE,0BAA0B;EACvD","sourcesContent":[".product-banner-form {\n    background-color: #ffffff;\n    padding: 30px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    width: 400px;\n    margin: 0 auto;\n  }\n  \n  .product-banner-form h3 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .product-banner-form .section {\n    margin-bottom: 20px;\n  }\n  \n  .product-banner-form label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 8px;\n  }\n  \n  .product-banner-form input[type=\"text\"],\n  .product-banner-form textarea,\n  .product-banner-form input[type=\"file\"] {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .product-banner-form textarea {\n    height: 100px;\n    resize: vertical;\n  }\n  \n  .product-banner-form .submit-button {\n    background-color: #90ee90; /* Light green */\n    border: none;\n    padding: 12px 20px;\n    font-size: 16px;\n    color: white;\n    cursor: pointer;\n    border-radius: 5px;\n    width: 100%;\n  }\n  \n  .product-banner-form .submit-button:hover {\n    background-color: #7ecf7e; /* Darker green on hover */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
