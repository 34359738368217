import React, { useState, useEffect } from 'react';
import '../cart/cart.css'; // Add or update your cart-specific CSS styles here
import carticon from '../../Assets/images/cart.svg'; // Import cart icon for display
import api from '../../API/api'; // Import Axios for API requests

const Cart = ({ isVisible, toggleCart, cartItems, setCartItems}) => {
    // const [cartItems, setCartItems] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [totalAmount, setTotalAmount] = useState(0); // State for total cart amount
    let safeCartItems = Array.isArray(cartItems) ? cartItems : [];

    // Fetch cart items and user details on component mount
    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const response = await api.get('/cart'); // Assuming the GET endpoint is `/api/cart`
                setCartItems(response.data.items); // Set the cart items
            } catch (error) {
                console.error('Error fetching cart items:', error);
            }
        };

        const fetchUserDetails = async () => {
            try {
                const response = await api.get('/auth/profile'); // Assuming the GET endpoint for user details is `/api/user/profile`
                setUserDetails(response.data); // Set user details
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchCartItems();
        fetchUserDetails();
    }, []);

    // Calculate total amount whenever cart items change
    useEffect(() => {
        const calculateTotalAmount = () => {
            const total = safeCartItems.reduce(
                (sum, item) => sum + item.price * item.quantity,
                0
            );
            setTotalAmount(total);
        };

        calculateTotalAmount();
    }, [safeCartItems]);


    

    // Handle removing an item from the cart
    const handleRemoveFromCart = async (productId) => {
        try {
            const response = await api.post('cart/remove', { productId }); // Assuming the POST endpoint for removal is `/api/cart/remove`
            setCartItems(response.data.items); // Update cart items after removal
        } catch (error) {
            console.error('Error removing product from cart:', error);
        }
    };

    // Handle place order button click
    const handlePlaceOrder = async () => {
        const { phone, address } = userDetails;
        if (!phone || !address) {
            alert('Please update your phone number and address in your profile to proceed with the order.');
            window.location.href = '/profile'; // Redirect to profile page
            return;
        }

        try {
            // Prepare order details message
            const orderDetails = `
                New Order Received!\n\n
                Customer: ${userDetails.name}\n
                Phone: ${userDetails.phone}\n
                Address: ${userDetails.address}\n
                Order Items:\n
                ${safeCartItems.map(item => `${item.product.name} Size:${item.size} (x${item.quantity}) - $${item.price * item.quantity}`).join('\n')}\n
                Total Amount: $${totalAmount.toFixed(2)}
            `;

            // URL-encode the message to be sent via WhatsApp
            const message = encodeURIComponent(orderDetails);

            // Replace with the business owner's WhatsApp number
            const ownerPhone = '96896123469'; // Replace with actual WhatsApp phone number
            const waLink = `https://wa.me/${ownerPhone}?text=${message}`;

            // Redirect to WhatsApp
            window.open(waLink, '_blank');

            alert('Order placed successfully! You will be redirected to WhatsApp to confirm the order.');
            setCartItems([])
            await clearCartInBackend();



        } catch (error) {
            console.error('Error placing order:', error);
            alert('There was an issue placing your order. Please try again.');
        }
    };
    const clearCartInBackend = async () => {
        try {
            // Send a request to the backend to clear the cart
            await api.post('/cart/clear'); // Assuming the endpoint to clear the cart is `/api/cart/clear`
        } catch (error) {
            console.error('Error clearing cart in backend:', error);
            alert('There was an issue clearing your cart. Please try again.');
        }
    };

    return (
        <div className={`cart ${isVisible ? 'active' : ''}`}>
            <div className="cart-content">
                {/* Cart Header Section */}
                <div className="cart-header">
                    <img src={carticon} alt="Cart Icon" className="cart-icon" />
                    <h2>Your Cart</h2>
                    <button onClick={toggleCart} className="close-btn">Close Cart</button>
                </div>

                {/* Cart Body Section */}
                <div className="cart-body">
                    {safeCartItems.length === 0 ? (
                        <p>Your cart is empty.</p>
                    ) : (
                        <div>
                            {/* Render cart items */}
                            {safeCartItems.map((item) => (
                                <div key={item.product._id} className="cart-item">
                                    <img 
                                        src={`https://backend.whitefeatherfashions.com/${item.product.images[0]}`} 
                                        alt={item.product.name} 
                                        className="cart-item-image" 
                                    />
                                    <div className="cart-item-details">
                                        <p>{item.product.name}</p>
                                        <p>Quantity: {item.quantity}</p>
                                        <p>Price: ${item.price * item.quantity}</p>
                                        <p>Size:{item.size}</p>
                                    </div>
                                    <button 
                                        onClick={() => handleRemoveFromCart(item.product._id)} 
                                        className="remove-btn"
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* Cart Footer Section */}
                {safeCartItems.length > 0 && (
                    <div className="cart-footer">
                        <div className="total-amount">
                            <p>Total Amount: AED {totalAmount.toFixed(2)}</p>
                        </div>
                        <button onClick={handlePlaceOrder} className="place-order-btn">Place Order</button>
                    </div>
                )}

                {/* Return to Shop Button */}
                <div className="cart-footer">
                    <button onClick={() => window.location.href = "/shop"} className="return-btn">Return to Shop</button>
                </div>
            </div>
        </div>
    );
};

// Set defaultProps to ensure cartItems is always an array
Cart.defaultProps = {
    cartItems: [],
};

export default Cart;
