import React, { useState, useEffect } from 'react';
import './ContactSec.css';
import api from '../../API/api'
import phonegold from '../../Assets/images/phone-gold.svg';
import mailgold from '../../Assets/images/envelope-gold.svg';
import locgold from '../../Assets/images/location-dot-gold.svg';


const ContactSec = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState('')
    const [image, setImage] = useState(null);
    const [location, setLocation] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    useEffect(() => {
      const fetchHomeBanner = async () => {
        try {
          const response = await api.get("/admin/contactsection");
          const { title, image, description, location, email, phone } = response.data.banner;
          
          setTitle(title || "");
          setImage(image || null);
          setDescription(description || '') 
          setLocation(location || '')
          setEmail(email || '')
          setPhone(phone || '')
        } catch (error) {
          console.error("Error fetching home banner:", error);
        }
      };
  
      fetchHomeBanner();
    }, []);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [responseMessage, setResponseMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/contact', formData);
            setResponseMessage(response.data.message);
            setFormData({ name: '', email: '', phone: '', message: '' });
        } catch (error) {
            console.error('Error submitting form:', error);
            setResponseMessage('Failed to send your enquiry. Please try again later.');
        }
    };

    return (
        <div className='contact-wrapp'>
                        <img 
                src={`https://backend.whitefeatherfashions.com/${image}`} 
                alt="Background" 
                className="contact-bg-image" 
            />
            <div className="wrapper">
                <div className='contact-row'>
                    <div className="contact-col1">
                        <h2>{title}</h2>
                        <p>{description}</p>
                        <ul className="contact-add">
                            <li><a href=""><span className="icon"><img src={locgold} alt=""/></span>{location}</a></li>
                            <li><a href="mailto:support@astratourz.com"><span className="icon"><img src={mailgold} alt=""/></span>{email}</a></li>
                            <li><a href="tel:+971507772526"><span className="icon"><img src={ phonegold} alt=""/></span>{phone}</a></li>
                        </ul>
                    </div>
                    <div className="contact-col2">
                        <div className="contact-form">
                            <h2>Say Something</h2>
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                />
                                <textarea
                                    name="message"
                                    placeholder="Message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                />
                                <button className="submit-btn global-btn">Send Enquiry</button>
                            </form>
                            {responseMessage && <p className="response-message">{responseMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSec;
