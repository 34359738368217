import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/FooterSection/FooterSection'
import Profile from '../../Components/Profile/Profile'

const profilePage = () => {
  return (
    <>
    <Navbar/>
    <Profile/>
    <Footer/>
    </>
  )
}

export default profilePage