import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

const userLoggedOut = () => {
    const user = localStorage.getItem('authToken')
  return (
    user ? <Navigate to='/index'/> : <Outlet/>
  )
}

export default userLoggedOut