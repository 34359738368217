// src/Components/WhatsAppIcon.jsx
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './whatsAppComponent.css'

const WhatsAppIcon = () => {
    return (
<div className="whatsappdiv">
        <a
            href="https://wa.me/96896123469"  // Replace with your WhatsApp number, including country code
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-icon"
        >
            <FaWhatsapp style={{ color: 'white', fontSize: '40px' }} />
        </a>
</div>
    );
};

export default WhatsAppIcon;
