import React from 'react';
import './AboutSection.css';
import aboutImage from '../../Assets/images/about-img.png';
import leaf from '../../Assets/images/goldenleaf.png';

const AboutSection = () => {
    return(
        <div className="about-sec">
            <span className="leaf">
                <img src={leaf} alt="" />
            </span>
            <div className='wrapper'>
                <div className="about-row">
                    <div className="about-col1">
                        <h3>About Us</h3>
                        <h2>The White Feather Experience</h2>
                        <p>Welcome to White Feather, an exclusive ladies' fashion boutique located in the heart of Abu Dhabi. At White Feather, we curate a timeless collection of elegant, sophisticated, and contemporary fashion for women who seek to express their individuality with style.</p>
                        <p>Our boutique offers a carefully selected range of high-quality apparel, from luxurious dresses to chic casual wear, designed to suit every occasion, whether it's a day at the office or a night out on the town. We believe that fashion is not just about clothing but about feeling confident, empowered, and beautiful in your own skin.</p>
                        <p>With a passion for craftsmanship and a keen eye for trends, our team is committed to providing personalized service to help you find pieces that perfectly complement your unique style. Whether you're seeking bold statement pieces or timeless classics, White Feather ensures that every visit is an unforgettable experience.</p>
                    </div>
                    <div className="about-col2">
                        <img src={aboutImage} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection