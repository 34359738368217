import React, { useState, useEffect } from 'react';
import api from '../../API/api';
import { useNavigate, useParams } from 'react-router-dom';
import './editProduct.css';

const EditProduct = () => {
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    stock: '',
    category: 'Saree',
    images: []
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await api.get(`/products/${id}`);
        setFormData(response.data.product);
      } catch (error) {
        console.error('Error fetching product details:', error.message);
      }
    };

    fetchProduct();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, images: e.target.files });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('name', formData.name);
    data.append('description', formData.description);
    data.append('price', formData.price);
    data.append('stock', formData.stock);
    data.append('category', formData.category);
    Array.from(formData.images).forEach((file) => data.append('images', file));

    try {
      const response = await api.put(`/products/edit/${id}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMessage(response.data.message);
      navigate('/admin-dashboard');  
    } catch (error) {
      setMessage('Error updating product: ' + error.message);
    }
  };

  return (
    <div className="edit-product">
      <h3>Edit Product</h3>
      {message && <p>{message}</p>}
      <form className="product-form" onSubmit={handleSubmit}>
        <label>
          Product Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter product name"
            required
          />
        </label>
        <label>
          Description:
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Enter product description"
            required
          ></textarea>
        </label>
        <label>
          Price:
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            placeholder="Enter price"
            required
          />
        </label>
        <label>
          Stock:
          <input
            type="number"
            name="stock"
            value={formData.stock}
            onChange={handleChange}
            placeholder="Enter stock quantity"
            required
          />
        </label>
        <label>
          Category:
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="Saree">Saree</option>
            <option value="Kurti">Kurti</option>
            <option value="Salwar Set">Salwar Set</option>
          </select>
        </label>
        <label>
          Images:
          <input
            type="file"
            name='images'
            multiple
            onChange={handleFileChange}
          />
        </label>
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default EditProduct;
