import React, { useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/FooterSection/FooterSection';
import Authentication from '../../Components/Authentication/authentication';

const RegisterPage = () => {

    return (
        <> <Navbar />
            <Authentication />
            <Footer /></>

    );
};

export default RegisterPage;
