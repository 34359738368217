import React, { useState, useEffect } from 'react';
import api from '../../API/api';
import './AboutBannerForm.css'; 

const AboutBannerForm = () => {
  const [topTitle, setTopTitle] = useState('');
  const [topDescription, setTopDescription] = useState('');
  const [topImage, setTopImage] = useState(null);

  const [bottomTitle, setBottomTitle] = useState('');
  const [bottomDescription, setBottomDescription] = useState('');
  const [bottomImage, setBottomImage] = useState(null);
  const [message, setMessage] = useState('')

  useEffect(() => {
    const fetchHomeBanner = async () => {
      try {
        const response = await api.get("/admin/aboutbanner");
        const { topSection,
            bottomSection
        } = response.data.banner;
        
        setTopTitle(topSection.title || '')
        setTopDescription(topSection.description || '')
        setTopImage(topSection.image || null)
        setBottomTitle(bottomSection.title || '')
        setBottomDescription(bottomSection.description || '')
        setBottomImage(bottomSection.image || null)

      } catch (error) {
        console.error("Error fetching product banner:", error);
      }
    };

    fetchHomeBanner();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('topTitle', topTitle);
    formData.append('topDescription', topDescription);
    if (topImage) formData.append('topImage', topImage);
    
    formData.append('bottomTitle', bottomTitle);
    formData.append('bottomDescription', bottomDescription);
    if (bottomImage) formData.append('bottomImage', bottomImage);

    try {
        const response = await api.put("/admin/aboutbanner", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          setMessage(response.data.message);
    } catch (error) {
      console.error('Error updating product banner:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="product-banner-form" encType="multipart/form-data">
      {/* Top Section */}
      <div className="section">
        <h3>Top Section</h3>
        <label htmlFor="topTitle">Title:</label>
        <input
          type="text"
          id="topTitle"
          value={topTitle}
          onChange={(e) => setTopTitle(e.target.value)}
          required
        />

        <label htmlFor="topDescription">Description:</label>
        <textarea
          id="topDescription"
          value={topDescription}
          onChange={(e) => setTopDescription(e.target.value)}
          required
        ></textarea>

        <label htmlFor="topImage">Image (Top Section):</label>
        <input
          type="file"
          id="topImage"
          accept="image/*"
          onChange={(e) => setTopImage(e.target.files[0])}
          required
        />
      </div>

      {/* Bottom Section */}
      <div className="section">
        <h3>Bottom Section</h3>
        <label htmlFor="bottomTitle">Title:</label>
        <input
          type="text"
          id="bottomTitle"
          value={bottomTitle}
          onChange={(e) => setBottomTitle(e.target.value)}
          required
        />

        <label htmlFor="bottomDescription">Description:</label>
        <textarea
          id="bottomDescription"
          value={bottomDescription}
          onChange={(e) => setBottomDescription(e.target.value)}
          required
        ></textarea>

        <label htmlFor="bottomImage">Image (Bottom Section):</label>
        <input
          type="file"
          id="bottomImage"
          accept="image/*"
          onChange={(e) => setBottomImage(e.target.files[0])}
          required
        />
      </div>

      <button type="submit" className="submit-button">Update Product Banner</button>
      {message && (
          <div
            className={`update-banner-message ${
              message.includes("successfully") ? "success" : "error"
            }`}
          >
            {message}
          </div>
        )}
    </form>
  );
};

export default AboutBannerForm;
