import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './adminLogin.css';
import logo from '../../Assets/images/logo.svg'; 
import api from '../../API/api';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
     const token = localStorage.getItem('adminToken')
     if(token) {
       navigate('/admin-dashboard')
     }
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await api.post('/admin/login', { email, password });
      const { token } = response.data;
      localStorage.setItem('adminToken', token); 
      alert('Login Successful');
      setLoading(false);
      // Redirect to admin dashboard
      window.location.href = '/admin-dashboard';
    } catch (err) {
      setError(err.response?.data?.message || 'Something went wrong');
      setLoading(false);
    }
  };

  return (
    <div className="admin-login">
      <div className="login-container">
        <img src={logo} alt="Admin Logo" className="login-logo" />
        <h2>Admin Login</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;