import React from 'react';
import './App.css';
import AboutSection from './Components/AboutSection/AboutSection';
import HeroSection from './Components/HeroSection/HeroSection';
import Navbar from './Components/Navbar/Navbar';
import NewArrivals from './Components/NewArrivals/NewArrivals';
import CategorySection from './Components/CategorySection/CategorySection';
import ServiceSection from './Components/ServiceSection/ServiceSection';
import Footer from './Components/FooterSection/FooterSection';

function App() {
  return (
    <>
      <Navbar/>
      <HeroSection/>
      <NewArrivals/>
      <CategorySection/>
      <AboutSection/>
      <ServiceSection/>
      <Footer/>
    </>
  );
}

export default App;
