import React, { useState, useEffect } from 'react';
import api from '../../API/api' 
import './adminDashboard.css';
import { useNavigate } from 'react-router-dom';
import UpdateHomeBanner from '../AdminHomeBanner/BannerForm';
import AboutBannerForm from '../AdminAboutBanner/AboutBannerForm';
import UpdateProductBanner from '../AdminProductBanner/AdminProductBanner';
import UpdateContactSection from '../AdminContactSection/ContactSectionForm';
import ResetPassword from '../ResetPassword/ResetPassword';
const AdminDashboard = () => {
  const [selectedTab, setSelectedTab] = useState('viewProducts');
  const navigate = useNavigate()
    useEffect(() => {
       const token = localStorage.getItem('adminToken')
       if(!token) {
         navigate('/admin-login')
       }
    },[])

  const renderContent = () => {
    switch (selectedTab) {
      case 'viewProducts':
        return <ViewProducts />;
      case 'addProduct':
        return <AddProduct />;
        case 'updateHomeBanner':
          return <UpdateHomeBanner/>
        case 'updateAboutBanner':
          return <AboutBannerForm/>
         case 'updateProductBanner': 
         return <UpdateProductBanner/>
         case 'updateContactSection':
          return <UpdateContactSection/>
          case 'updateCredentials':
            return <ResetPassword/>

      default:
        return <ViewProducts />;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken'); // Remove token from localStorage
    navigate('/admin-login'); // Redirect to the login page
  };
  

  return (
    <div className="admin-dashboard">
      <nav className="sidebar">
        <h2>Admin Dashboard</h2>
        <ul>
          <li
            className={selectedTab === 'viewProducts' ? 'active' : ''}
            onClick={() => setSelectedTab('viewProducts')}
          >
            View Products
          </li>
          <li
            className={selectedTab === 'addProduct' ? 'active' : ''}
            onClick={() => setSelectedTab('addProduct')}
          >
            Add New Product
          </li>
          <li
            className={selectedTab === 'updateHomeBanner' ? 'active' : ''}
            onClick={() => setSelectedTab('updateHomeBanner')}
          >
            Update home page banner
          </li>
          <li
            className={selectedTab === 'updateAboutBanner' ? 'active' : ''}
            onClick={() => setSelectedTab('updateAboutBanner')}
          >
            Update about page banner
          </li>
          <li
            className={selectedTab === 'updateProductBanner' ? 'active' : ''}
            onClick={() => setSelectedTab('updateProductBanner')}
          >
            Update product page banner
          </li>
          <li
            className={selectedTab === 'updateContactSection' ? 'active' : ''}
            onClick={() => setSelectedTab('updateContactSection')}
          >
            Update contact section
          </li>
          <li
            className={selectedTab === 'updateCredentials' ? 'active' : ''}
            onClick={() => setSelectedTab('updateCredentials')}
          >
            Update credentials
          </li>
        </ul>
        <button className='logout-button' onClick={handleLogout}>Logout</button>
      </nav>
      <main className="content">{renderContent()}</main>
    </div>
  );
};

// ViewProducts Component
const ViewProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();  // Updated to useNavigate

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.get('/products/list');
        setProducts(response.data.products);
      } catch (error) {
        console.error('Error fetching products:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit-product/${id}`); // Correctly using template literals
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this product?');
    if (confirmDelete) {
      try {
        await api.delete(`/products/delete/${id}`);
        setProducts((prevProducts) => prevProducts.filter((product) => product._id !== id));
      } catch (error) {
        console.error('Error deleting product:', error.message);
      }
    }
  };

  if (loading) {
    return <p>Loading products...</p>;
  }

  return (
    <div className="view-products">
      <h3>Products</h3>
      {products.length > 0 ? (
        <div className="products-grid">
          {products.map((product) => {
            // Normalize the image path
            const imageUrl =
              product.images && product.images.length > 0
  ? `https://backend.whitefeatherfashions.com/${product.images[0].replace(/\\/g, '/')}`
  : 'https://via.placeholder.com/150';


            return (
              <div key={product._id} className="product-card">
                <img src={imageUrl} alt={product.name} className="product-image" />
                <div className="product-info">
                  <h4>{product.name}</h4>
                  <p>{product.productCode}</p>
                  <p>{product.description}</p>
                  <p>Price: ${product.price}</p>
                  <p>Stock: {product.stock}</p>
                  <p>Category: {product.category}</p>
                </div>
                <div className="product-actions">
                  <button onClick={() => handleEdit(product._id)} className="edit-button">Edit</button>
                  <button onClick={() => handleDelete(product._id)} className="delete-button">Delete</button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No products available.</p>
      )}
    </div>
  );
};


// AddProduct Component
const AddProduct = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    stock: '',
    category: 'Saree',
    images: []
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = new FormData();
    data.append('name', formData.name);
    data.append('description', formData.description);
    data.append('price', formData.price);
    data.append('stock', formData.stock);
    data.append('category', formData.category);
    console.log('Images:', formData.images);

    Array.from(formData.images).forEach((file) => data.append('images', file)); 

  
    try {
      const response = await api.post('/products/add', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMessage(response.data.message);
      setFormData({
        name: '',
        description: '',
        price: '',
        stock: '',
        category: 'Saree',
        images: []
      });
    } catch (error) {
      setMessage('Error adding product: ' + error.message);
    }
  };
  

  return (
    <div className="add-product">
      <h3>Add New Product</h3>
      {message && <p>{message}</p>}
      <form className="product-form" onSubmit={handleSubmit}>
        <label>
          Product Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter product name"
            required
          />
        </label>
        <label>
          Description:
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Enter product description"
            required
          ></textarea>
        </label>
        <label>
          Price:
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            placeholder="Enter price"
            required
          />
        </label>
        <label>
          Stock:
          <input
            type="number"
            name="stock"
            value={formData.stock}
            onChange={handleChange}
            placeholder="Enter stock quantity"
            required
          />
        </label>
        <label>
          Category:
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="Saree">Saree</option>
            <option value="Kurti">Kurti</option>
            <option value="Salwar Set">Salwar Set</option>
          </select>
        </label>
        <label>
          Images:
          <input
  type="file"
  name='images'
  multiple
  onChange={(e) => setFormData({ ...formData, images: e.target.files })}
/>

        </label>
        <button type="submit">Add Product</button>
      </form>
    </div>
  );
};

export default AdminDashboard;