import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import './ProductInner.css';
import Product1 from '../../Assets/images/produt1.jpg';
import Like from '../../Assets/images/heart-regular.svg';
import CartG from '../../Assets/images/cart-gold.svg';
import api from "../../API/api";

const ProductInner = ({ onCategoryChange } ) => {
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [selectedSize, setSelectedSize] = useState('')
    const [quantity, setQuantity] = useState(1); // State for quantity

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await api.get(`/products/${id}`); // Fixed endpoint syntax
                setProduct(response.data.product);
                onCategoryChange(product.category)
            } catch (error) {
                console.error("Error fetching product:", error.message);
            }
        };
        fetchProduct();
    }, [id,onCategoryChange]);



    const handleAddToCart = async () => {
        try {
            const response = await api.post('/cart/add', { productId: product._id, quantity, selectedSize });
            alert(response.data.message); // Notify user of success
        } catch (error) {
            console.error('Error adding to cart:', error);
            alert(error.response?.data?.error || 'Failed to add product to cart.');
        }
    };
    const handleAddToWishList = async () => {
        try {
            const response = await api.post('/wishlist/add', { productId: product._id})
            alert(response.data.message)
        } catch (error) {
            console.error('Error adding to cart:', error);
            alert(error.response?.data?.error || 'Failed to add product to wishlist');
            
        }
    }
    const handleSizeSelection = (size) => {
        setSelectedSize(size);
    };

    const increaseQuantity = () => setQuantity((prev) => prev + 1);
    const decreaseQuantity = () => setQuantity((prev) => (prev > 1 ? prev - 1 : 1)); // Prevent going below 1

    return (
        <div className="product-inner">
            <div className="wrapper">
                <div className="detail-row">
                    <div className="detail-col1">
                        <div className="detail-col3">
                            <div className="detail-col4">
                                {product.images && product.images.length > 0 ? (
                                    product.images.map((image, index) => (
                                        <div className="detail-img" key={index}>
                                            <img
                                                src={`https://backend.whitefeatherfashions.com/${image.replace(/\\/g, '/')}`}
                                                alt={`Product image ${index + 1}`}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div className="detail-img">
                                        <img src={Product1} alt="Placeholder" />
                                    </div>
                                )}
                            </div>
                            <div className="detail-col5">
                                <img
                                    src={
                                        product.images && product.images[0]
                                            ? `https://backend.whitefeatherfashions.com/${product.images[0].replace(/\\/g, '/')}`
                                            : Product1
                                    }
                                    alt="Main product"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="detail-col2">
                        <h2>{product.name || "Product Name"}</h2>
                        <p>Product code:{product.productCode}</p>
                        <div className="price-detail">
                            <span className="price">AED {product.price || "0.00"}</span>
                        </div>
                        <div className="color-sec">
                            <h3>Color: {product.color || "Not specified"}</h3>
                        </div>

                            
                            <div className="size-sec">
                            <h3>Size:</h3>
                            <div className="size-boxes">
                                <button
                                    className={`size ${selectedSize === "S" ? "selected" : ""}`}
                                    onClick={() => handleSizeSelection("S")}
                                >
                                    S
                                </button>
                                <button
                                    className={`size ${selectedSize === "M" ? "selected" : ""}`}
                                    onClick={() => handleSizeSelection("M")}
                                >
                                    M
                                </button>
                                <button
                                    className={`size ${selectedSize === "L" ? "selected" : ""}`}
                                    onClick={() => handleSizeSelection("L")}
                                >
                                    L
                                </button>
                            </div>

                            
                        </div>
                        <div className="quantity-sec">
                            <h3>Qty:</h3>
                            <div className="quantity">
                                <button className="minus" onClick={decreaseQuantity}>-</button>
                                <span className="count">{quantity}</span>
                                <button className="plus" onClick={increaseQuantity}>+</button>
                            </div>
                        </div>
                        <div className="action-sec">
                            <button className="wish-btn" onClick={() =>handleAddToWishList()}>
                                <span className="icon">
                                    <img src={Like} alt="" />
                                </span>{" "}
                                Add Wishlist
                            </button>
                            <button
                                className="add-btn"
                                onClick={handleAddToCart}
                            >
                                <span className="icon">
                                    <img src={CartG} alt="" />
                                </span>{" "}
                                Add To Cart
                            </button>
                        </div>
                        <div className="content-sec">
                            <p>{product.description || "No description available."}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default ProductInner;

