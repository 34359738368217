import { useState, useEffect} from 'react';
import './HeroSection.css';
import bannerBg from '../../Assets/images/banner-img.jpg';
import { Link } from 'react-router-dom';
import api from '../../API/api';


const HeroSection = () => {

      const [title, setTitle] = useState("");
      const [description, setDescription] = useState('')
      const [image, setImage] = useState(null);
    useEffect(() => {
        const fetchHomeBanner = async () => {
          try {
            const response = await api.get("/admin/homebanner");
            const { title, image, description } = response.data;
            setTitle(title || "");
            setImage(image || null);
            setDescription(description || '') 
          } catch (error) {
            console.error("Error fetching home banner:", error);
          }
        };
    
        fetchHomeBanner();
      }, []);
    return(

        <div className="bannerSec">
            <div className="banner-bg">
                <img src={`https://backend.whitefeatherfashions.com/${image}`} alt="" />
            </div>
            <div className="banner-content">
                <div className="wrapper">
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <button className="banner-btn global-btn"> <Link to='/shop'>Explore</Link></button>

                    
                </div>
            </div>
            <div className="img">
            </div>
        </div>
    )
}

export default HeroSection