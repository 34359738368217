import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import ProductSec from "../../Components/ProductSec/ProductSec";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import Footer from "../../Components/FooterSection/FooterSection";



const Products = () => {
    return(
        <>
            <Navbar/>
            <InnerBanner/>
            <ProductSec/>
            <Footer/>
        </>
    )
}

export default Products