import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../API/api";
import './Profile.css';

const Profile = () => {
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [enteredOtp, setEnteredOtp] = useState("");
  const [loading, setLoading] = useState(true);

  // Fetch user details from backend
  useEffect(() => {
    const fetchProfile = async () => {
        try {
          const token = localStorage.getItem("authToken");
          if (!token) {
            throw new Error("No token found in localStorage");
          }
      
          const response = await api.get("/auth/profile", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setProfile(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching profile:", error);
          setLoading(false);
        }
      };
      
    fetchProfile();
  }, []);

  // Input Change Handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
    validateField(name, value);
  };

  // Validation Handler
  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "name":
        if (!value.trim()) {
          error = "Name is required.";
        } else if (value.length < 3) {
          error = "Name must be at least 3 characters long.";
        }
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value.trim()) {
          error = "Email is required.";
        } else if (!emailRegex.test(value)) {
          error = "Invalid email format.";
        }
        break;
      case "phone":
        const phoneRegex = /^[0-9]{10}$/;
        if (!value.trim()) {
          error = "Phone number is required.";
        } else if (!phoneRegex.test(value)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "address":
        if (!value.trim()) {
          error = "Address is required.";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateForm = () => {
    const formErrors = {};
    Object.keys(profile).forEach((field) => validateField(field, profile[field]));
    return Object.values(formErrors).every((error) => !error);
  };

  // Save Profile Handler
  const saveProfile = async () => {
    if (!validateForm()) {
      alert("Please fix the errors before saving.");
      return;
    }
    try {
      const response = await api.put("/auth/profile", profile); 
      alert("Profile updated successfully!");
      setEditMode(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile.");
    }
  };

  // OTP-related actions
  const sendOtp = async () => {
    if (!profile.phone) {
      alert("Enter a valid phone number before sending OTP.");
      return;
    }
    try {
      const response = await api.post("/otp/send-otp", { phone: profile.phone });
      alert("OTP sent to your phone.");
      setOtpSent(true);
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Failed to send OTP.");
    }
  };
  

  const verifyOtp = async () => {
    if (!enteredOtp) {
      alert("Please enter the OTP.");
      return;
    }
    try {
      const response = await api.post("/otp/verify-otp", {
        phone: profile.phone,
        otp: enteredOtp,
      });
  
      if (response.data.success) {
        alert("Phone number verified successfully!");
        setOtpSent(false);
        setEnteredOtp("");
      } else {
        alert(response.data.error || "Invalid OTP!");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      // Handle specific error responses
      if (error.response && error.response.data && error.response.data.error) {
        alert(error.response.data.error);  // Show the error message from backend
      } else {
        alert("Failed to verify OTP.");
      }
    }
  };
  
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main-container">
      <div className="profile-container">
      <h2>My Profile</h2>
      {editMode ? (
        <div className="edit-profile">
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={profile.name}
              onChange={handleInputChange}
            />
            {errors.name && <small className="error">{errors.name}</small>}
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={profile.email}
              onChange={handleInputChange}
            />
            {errors.email && <small className="error">{errors.email}</small>}
          </label>
          <label>
            Address:
            <textarea
              name="address"
              value={profile.address}
              onChange={handleInputChange}
            />
            {errors.address && <small className="error">{errors.address}</small>}
          </label>
                <label>
        Phone:
        <input
            type="text"
            name="phone"
            value={profile.phone}
            onChange={handleInputChange}
        />
        {errors.phone && <small className="error">{errors.phone}</small>}
        {/* <button  onClick={sendOtp} disabled={otpSent}>
            {otpSent ? "OTP Sent" : "Send OTP"}
        </button> */}
        </label>
        {/* {otpSent && (
        <div>
            <label>
            Enter OTP:
            <input
                type="text"
                value={enteredOtp}
                onChange={(e) => setEnteredOtp(e.target.value)}
            />
            </label>
            <button onClick={verifyOtp}>Verify OTP</button>
        </div>
        )} */}

          <button onClick={saveProfile}>Save</button>
          <button onClick={() => setEditMode(false)}>Cancel</button>
        </div>
      ) : (
        <div className="profile-details">
          <p><strong>Name:</strong> {profile.name}</p>
          <p><strong>Email:</strong> {profile.email}</p>
          <p><strong>Address:</strong> {profile.address || "Not provided"}</p>
          <p><strong>Phone:</strong> {profile.phone || "Not provided"}</p>
          <button onClick={() => setEditMode(true)}>Edit Profile</button>
        </div>
      )}
    </div>
    </div>
    
  );
};

export default Profile;
