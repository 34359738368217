import React, { useState } from 'react';
import '../Authentication/authentication.css'; // Optional for styling
import api from '../../API/api'
import { useNavigate } from 'react-router-dom';

const Authentication = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors = {};

        // Validate Name (only for registration)
        if (!isLogin && !formData.name) {
            newErrors.name = 'Name is required';
        }

        // Validate Email
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email';
        }

        // Validate Password
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            newErrors.password = 'Password should be at least 6 characters long';
        }

        // Validate Confirm Password (only for registration)
        if (!isLogin && formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = "Passwords don't match";
        }

        setErrors(newErrors);

        // Return true if no errors, else false
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        const action = isLogin ? 'Logging in' : 'Registering';
        // Add API call or other logic here
        try {
            let response
            if (isLogin) {
                response = await api.post('/auth/login', {
                    email: formData.email,
                    password: formData.password
                })
                alert('Login successfull...')
            } else {
                response = await api.post('/auth/register', {
                    name: formData.name,
                    email: formData.email,
                    password: formData.password
                })
                alert('Registration successful')
            }
            if (response.data?.token) {
                localStorage.setItem('authToken', response.data.token);
                navigate('/index');
            } else {
                console.error('No token received.');
            }

        } catch (error) {
            console.error('Error:', error.response?.data?.message || error.message);
            alert(error.response?.data?.message || 'An error occured!')
        }
    };

    return (
        <div className='register-section'> <div className="register-page">
            <h2 className='register-title'>{isLogin ? 'Login' : 'Register'}</h2>
            <form onSubmit={handleSubmit}>
                {!isLogin && (
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}

                            placeholder="Enter your name" />
                        {errors.name && <p className="error">{errors.name}</p>}
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}

                        placeholder="Enter your email" />
                    {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}

                        placeholder="Enter your password" />
                    {errors.password && <p className="error">{errors.password}</p>}
                </div>
                {!isLogin && (
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}

                            placeholder="Confirm your password" />
                        {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                    </div>
                )}
                <button type="submit" className="btn">
                    {isLogin ? 'Login' : 'Register'}
                </button>
            </form>
            <p className='authentication-toggle-link'>
                {isLogin
                    ? "Don't have an account? "
                    : 'Already have an account? '}
                <span
                    className="toggle-link"
                    onClick={() => setIsLogin(!isLogin)}
                >
                    {isLogin ? 'Register here' : 'Login here'}
                </span>
            </p>
        </div>
        </div>

    );
};

export default Authentication;
