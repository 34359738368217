import React, { useState } from 'react';
import './ResetPassword.css';
import api from '../../API/api';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
    
        // Password length validation
        if (password.length < 6) {
            setErrorMessage('Password must be at least 6 characters long.');
            return;
        }
    
        // Password matching validation
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            return;
        }
    
        try {
            // Use axios API to send PUT request
            const response = await api.put('/admin/reset-password', {
                newPassword: password,  // Send the newPassword field
            });
    
            if (response.status === 200) {
                setSuccessMessage(response.data.message || 'Password updated successfully!');
                setPassword('');
                setConfirmPassword('');
            } else {
                setErrorMessage(response.data.message || 'Failed to update password.');
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'An error occurred. Please try again later.');
        }
    };
    

    return (
        <div className="reset-password-container">
            <form className="reset-password-form" onSubmit={handleSubmit}>
                <h2>Reset Password</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}

                <div className="form-group">
                    <label htmlFor="password">New Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter new password"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input
                        type="password"
                        id="confirm-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm new password"
                        required
                    />
                </div>

                <button type="submit" className="update-button">Update Password</button>
            </form>
        </div>
    );
};

export default ResetPassword;
