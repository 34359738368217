import React from "react";
import './CategorySection.css';
import saree from '../../Assets/images/category1.jpeg';
import Kurti from '../../Assets/images/category2.jpeg';
import salwar from '../../Assets/images/category3.jpeg';
import { Link, useNavigate } from "react-router-dom";

const CategorySection = () => {

    const navigate = useNavigate();  // Hook to navigate programmatically

    const handleCategoryClick = () => {
        // Navigate to the product detail page
        navigate('/product-detail');
    };

    return (
        <div className="category-sec">
            <div className="wrapper">
                <div className="category-row">
                    <div className="category-col1">
                        <h2>Shop By Category</h2>
                    </div>
                    <div className="category-col2">
                        <div className="category-box">
                            <Link to='/product?category=Saree'>
                                <img src={saree} alt="Saree" />
                            </Link>
                            <div className="category-name">
                                <h3><Link className="category-name-link-title" to='/product?category=Saree'>Saree</Link></h3>
                            </div>
                        </div>
                        <div className="category-box">
                            <Link to='/product?category=Kurti'>
                                <img src={Kurti} alt="Kurti" />
                            </Link>
                            <div className="category-name">
                                <h3><Link className="category-name-link-title" to='/product?category=Kurti'>Kurti</Link></h3>
                            </div>
                        </div>
                        <div className="category-box">
                            <Link to='/product?category=Salwar Set'>
                                <img src={salwar} alt="Salwar Set" />
                            </Link>
                            <div className="category-name">
                                <h3><Link className="category-name-link-title" to='/product?category=Salwar Set'>Salwar Set</Link></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategorySection;
