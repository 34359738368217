import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import AboutBanner from '../../Components/AboutBanner/AboutBanner';
import AboutsSection from '../../Components/AboutsSection/AboutsSection';
import ServiceSection from '../../Components/ServiceSection/ServiceSection';
import Footer from '../../Components/FooterSection/FooterSection';

function About() {
  return (
    <>
        <Navbar/>
        <AboutBanner/>
        <AboutsSection/>
        <ServiceSection/>
        <Footer/>
    </>
  );
}

export default About;
