import { useEffect, useState} from 'react';
import './AboutBanner.css';
import bannerImg from '../../Assets/images/about-banner.jpg';
import shape from '../../Assets/images/shapes.png';
import api from '../../API/api';

const AboutBanner = () => {
    const [topTitle, setTopTitle] = useState('');
    const [topDescription, setTopDescription] = useState('');
    const [topImage, setTopImage] = useState(null);
  
    const [bottomTitle, setBottomTitle] = useState('');
    const [bottomDescription, setBottomDescription] = useState('');
    const [bottomImage, setBottomImage] = useState(null);
  useEffect(() => {
      const fetchHomeBanner = async () => {
        try {
          const response = await api.get("/admin/aboutbanner");
          const { topSection,
              bottomSection
          } = response.data.banner;
          console.log(response.data);
          
          setTopTitle(topSection.title || '')
          setTopDescription(topSection.description || '')
          setTopImage(topSection.image || null)
          setBottomTitle(bottomSection.title || '')
          setBottomDescription(bottomSection.description || '')
          setBottomImage(bottomSection.image || null)
  
        } catch (error) {
          console.error("Error fetching product banner:", error);
        }
      };
  
      fetchHomeBanner();
    }, []);
    return(
        <div className="about-banner">
           <div className='about-banner-left'>
                <div className="about-banner-img">
                    <img src={`https://backend.whitefeatherfashions.com/${topImage}`} alt="" />                    
                </div>
                <span className='shape'>
                        <img src={shape} alt="" />
                </span>
           </div>
           <div className='about-banner-conten'>
                <div className="wrapper">
                    <div className='about-col1'>
                        <h2>{topTitle}</h2>
                        <p>{topDescription}</p>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default AboutBanner