import React, { useEffect, useState } from 'react';
import './Navbar.css';
import logo from '../../Assets/images/logo.svg';
import carticon from '../../Assets/images/cart.svg';
import wishlisticon from '../../Assets/images/wishlist.svg'
import usericon from '../../Assets/images/user-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import Cart from '../cart/cart';
import defaultAvatar from '../../Assets/images/avatar-character.avif';
import WishList from '../Wishlist/wishlist';


const Navbar = () => {
    const [cartItems, setCartItems] = useState([]);
    const [wishlist, setWishList] = useState([])
    const [isCartVisible, setIsCartVisible] = useState(false);
    const [isWishlistVisible, setIsWishListVisible] = useState(false)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const navigate = useNavigate();


    const categories = ['Saree', 'Kurti', 'Salwar Set', 'New Arrivals'];

    const cartItemCount = Array.isArray(cartItems) ? cartItems.length : 0;

    const toggleCart = () => setIsCartVisible(!isCartVisible);
    const toggleWishlist = () => setIsWishListVisible(!isWishlistVisible)
    const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);


    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) setIsUserLoggedIn(true);


        const savedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
        setCartItems(savedCart);


        return () => setIsSidebarOpen(false);
    }, []);


    useEffect(() => {
        const handleClickOutside = (event) => {
            const sidebar = document.querySelector('.mobile-sidebar');
            const hamburger = document.querySelector('.hamburger-menu');
            if (sidebar && !sidebar.contains(event.target) && !hamburger.contains(event.target)) {
                setIsSidebarOpen(false);
            }
        };


        if (isSidebarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }


        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);


    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('cartItems');
        setIsUserLoggedIn(false);
        setCartItems([]);
        setIsSidebarOpen(false);
        navigate('/register-login');
    };


    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setIsSidebarOpen(false);
        navigate(`/product?category=${category}`);
    };

    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        const sticky = navbar.offsetTop;

        const handleScroll = () => {
            if (window.pageYOffset > sticky) {
                navbar.classList.add('sticky');
            } else {
                navbar.classList.remove('sticky');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className='navbar'>
                <div className='wrapper'>
                    <button className="hamburger-menu" aria-label="Menu" onClick={toggleSidebar}>
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <g data-name="Layer 2" id="Layer_2">
                                <path d="M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z"></path>
                                <path d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"></path>
                                <path d="M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"></path>
                            </g>
                        </svg>
                    </button>


                    <div className='nav-logo'>
                        <img src={logo} alt="Logo" />
                    </div>


                    <div className='nav-menu'>
                        <ul>
                            <li className="always-visible"><Link to="/index">Home</Link></li>
                            <li className="always-visible"><Link to="/about">About Us</Link></li>
                            <li className="tablet-hidden"><Link to="/index">New Arrivals</Link></li>
                            <li className="tablet-hidden"><Link to="/product?category=Saree">Saree</Link></li>
                            <li className="tablet-hidden"><Link to="/product?category=Kurti">Kurti</Link></li>
                            <li className="tablet-hidden"><Link to="/product?category=Salwar Set">Salwar Set</Link></li>
                            <li className="always-visible"><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>


                    <div className='nav-right'>
                        {/* Desktop Cart and User Icons */}
                        <div className="desktop-icons">
                            <div className="nav-icon cart-icon" onClick={toggleCart}>
                                <img src={carticon} alt="Cart" />
                                <span className="cart-count">{cartItemCount}</span>
                            </div>
                            <div className="nav-icon cart-icon" onClick={toggleWishlist}>
                                <img src={wishlisticon} alt="WishList" />
                                <span className="cart-count">{wishlist.length}</span>
                            </div>

                            <div className="nav-icon user-icon" onClick={toggleDropdown}>
                                {isUserLoggedIn ? (
                                    <img
                                        src={defaultAvatar}
                                        alt="User"
                                        className="avatar-icon"
                                    />
                                ) : (
                                    <img src={usericon} alt="User" />
                                )}
                                <div className={`dropdown-menu ${isDropdownVisible ? 'show' : ''}`}>
                                    {isUserLoggedIn ? (
                                        <>
                                            <Link to="/profile">Profile</Link>
                                            <Link to="#" onClick={handleLogout}>Logout</Link>
                                        </>
                                    ) : (
                                        <Link to="/register-login">Login / Register</Link>
                                    )}
                                </div>
                            </div>
                        </div>


                        {/* Tablet Menu Icon */}
                        <div className="tablet-menu">
                            <Link to="#" onClick={toggleSidebar} className='nav-icon tablet-menu-icon'>
                                ☰
                            </Link>
                        </div>


                    </div>
                </div>
            </div>


            <div className={`mobile-sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <div className="sidebar-header">
                    <img src={logo} alt="Logo" className="sidebar-logo" />
                    <button className="close-sidebar" onClick={toggleSidebar}>×</button>
                </div>

                <div className="sidebar-user">
                    {isUserLoggedIn ? (
                        <div className="user-info">
                            <img src={defaultAvatar} alt="User Avatar" className="sidebar-avatar" />
                            <div className="user-actions">
                                <Link to="/profile" onClick={toggleSidebar}>Profile</Link>
                                <button onClick={handleLogout}>Logout</button>
                            </div>
                        </div>
                    ) : (
                        <Link to="/register-login" className="login-link" onClick={toggleSidebar}>
                            Login / Register
                        </Link>
                    )}
                </div>


                <nav className="sidebar-nav">
                    <ul>
                        <li><Link to="/index" onClick={toggleSidebar}>Home</Link></li>
                        <li><Link to="/index" onClick={toggleSidebar}>New Arrivals</Link></li>
                        <li><Link to="/product?category=Saree" onClick={() => handleCategoryChange('Saree')}>Saree</Link></li>
                        <li><Link to="/product?category=Kurti" onClick={() => handleCategoryChange('Kurti')}>Kurti</Link></li>
                        <li><Link to="/product?category=Salwar Set" onClick={() => handleCategoryChange('Salwar Set')}>Salwar Set</Link></li>
                        <li>
                            <Link to="#" onClick={(e) => { e.preventDefault(); toggleCart(); toggleSidebar(); }}>
                                Cart ({cartItemCount})
                            </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => { e.preventDefault(); toggleWishlist(); toggleSidebar(); }}>
                                Wishlist ({wishlist.length})
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>


            <div className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`} onClick={toggleSidebar}></div>


            <Cart isVisible={isCartVisible} toggleCart={toggleCart} cartItems={cartItems} setCartItems={setCartItems} />
            <WishList isVisible={isWishlistVisible} toggleWishlist={toggleWishlist} wishlistItems={wishlist} setWishListItems={setWishList} />
        </>
    );
};


export default Navbar;

