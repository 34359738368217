import React from "react";
import './ServiceSection.css';
import freeShip from '../../Assets/images/truck-fast-solid.svg';
import quality from '../../Assets/images/medal-solid.svg';
import secure from '../../Assets/images/lock-solid.svg';
import returnPolicy from '../../Assets/images/return.svg';


const ServiceSection = () => {
    return (
        <div className="service-sec">
            <div className="wrapper">
                <div className="service-row">
                    <div className="service-col1">
                        <div className="service-icon">
                            <img src={freeShip} alt="" />
                        </div>
                        <h3>Delivery Options</h3>
                        <p>We offer both Express and Normal delivery options. Express orders are shipped within 2-3 days, with tracking included. Normal delivery times may vary based on location.</p>
                    </div>
                    <div className="service-col1">
                        <div className="service-icon">
                            <img src={quality} alt="" />
                        </div>
                        <h3>Premium Quality</h3>
                        <p>Experience Timeless Elegance with our Replicas.</p>
                    </div>
                    <div className="service-col1">
                        <div className="service-icon">
                            <img src={secure} alt="" />
                        </div>
                        <h3>Secure Payments</h3>
                        <p>We accept Online payments.</p>
                    </div>
                    <div className="service-col1">
                        <div className="service-icon">
                            <img src={returnPolicy} alt="" />
                        </div>
                        <h3>Return and Replacement</h3>
                        <p>We accept returns only in cases where the product is found to be damaged at the time of delivery. A full replacement will be issued for such damaged products.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSection