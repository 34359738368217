import React, { useEffect, useState } from 'react';
import './NewArrivals.css';
import cart2 from '../../Assets/images/cart-gold.svg';
import leaf from '../../Assets/images/goldenleaf.png';
import api from '../../API/api'; // Axios instance
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const NewArrivals = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await api.get('/products/new-arrivals');
                setProducts(response.data.products);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const handleAddToCart = async (productId, quantity = 1) => {
        try {
            const user = localStorage.getItem('authToken')
            if(!user) {
                navigate('/register-login')
            }
            const response = await api.post('/cart/add', { productId, quantity });
            alert(response.data.message); // Notify user of success
        } catch (error) {
            console.error('Error adding to cart:', error);
            // alert(error.response?.data?.error || 'Failed to add product to cart.');
        }
    };
const viewSingleProduct = async (id) => {
    navigate(`/productDetails/${id}`)
}

    return (
        <div className="newarrival-sec">
            <span className="leaf">
                <img src={leaf} alt="" />
            </span>
            <div className="wrapper">
                <h2>New Arrivals</h2>
                <div className="newarrival-row">
                    {products.map((product) => {
                        const imageUrl1 =
                            product.images && product.images.length > 0
                                ? `https://backend.whitefeatherfashions.com/${product.images[0].replace(/\\/g, '/')}`
                                : 'https://via.placeholder.com/150';
                                const imageUrl2 =
                                product.images && product.images.length > 1
                                    ? `https://backend.whitefeatherfashions.com/${product.images[1].replace(/\\/g, '/')}`
                                    : 'https://via.placeholder.com/150';
                        return (
                            
                               <div key={product._id} className="newarrival-box">
                                <div className="newarrival-img" onClick={() =>viewSingleProduct(product._id)}>
                                    <img src={imageUrl1} alt={product.name} />
                                    {product.images[1] && (
                                        <span className="hover-img">
                                            <img src={imageUrl2} alt={product.name} />
                                        </span>
                                    )}
                                </div>
                               <h3 onClick={() =>viewSingleProduct(product._id)}>{product.name}</h3>
                               <p>{product.productCode}</p>
                                <h4 onClick={() =>viewSingleProduct(product._id)}>AED {product.price}</h4>
                                <button
                                    className="add-btn global-btn"
                                    onClick={() => handleAddToCart(product._id)}
                                >
                                    Add To Cart
                                    <span className="icon">
                                        <img src={cart2} alt="cart-icon" />
                                    </span>
                                </button>
                            </div>
                            
                                
                           
                            
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default NewArrivals;

