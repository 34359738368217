import React, { useState, useEffect } from 'react';
import '../Wishlist/wishlist.css';
import wishlisticon from '../../Assets/images/wishlist.svg';
import carticon from '../../Assets/images/cart.svg'
import deleteicon from '../../Assets/images/trash.png'
import api from '../../API/api';

const WishList = ({ isVisible, toggleWishlist, wishlistItems, setWishListItems }) => {
    // const [wishlistItems, setwishlistItems] = useState([]);
    const [userDetails, setUserDetails] = useState({});




    useEffect(() => {
        const fetchWishlist = async () => {
            try {

                const response = await api.get('/wishlist'); 
                
                

                setWishListItems(response.data.items || []);
            } catch (error) {
                console.error('Error fetching wishlist items:', error);
            }
        };

        const fetchUserDetails = async () => {
            try {
                const response = await api.get('/auth/profile');
                setUserDetails(response.data); // Set user details
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchWishlist();
        fetchUserDetails();

    }, []); // Empty dependency array means it runs only once when the component mounts
    








    // Handle removing an item from the cart
    const handleRemoveItem = async (productId) => {
        try {
            const response = await api.post('wishlist/remove', { productId });
            setWishListItems(response.data.items || []);
        } catch (error) {
            console.error('Error removing product from cart:', error);
        }
    };
    const handleAddToCart = async (productId) => {
        try {
            // Assuming the quantity is 1 for simplicity
            const quantity = 1;
            const response = await api.post('/cart/add', { productId, quantity });

            if (response.status === 200) {
                window.alert('Product added to cart');
            }
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
    };




    return (
        <div className={`cart ${isVisible ? 'active' : ''}`}>
            <div className="cart-content">
                {/* Cart Header Section */}
                <div className="cart-header">
                    <img src={wishlisticon} alt="Cart Icon" className="cart-icon" />
                    <h2>Your Wishlist</h2>
                    <button onClick={toggleWishlist} className="close-btn">Close Wishlist</button>
                </div>

                {/* Cart Body Section */}
                <div className="cart-body">
                    {wishlistItems.length === 0 ? (
                        <p>Your wishlist is empty.</p>
                    ) : (
                        <div>
                            {/* Render cart items */}
                            {wishlistItems.map((item) => (
                                <div key={item.product._id} className="wishlist-item">
                                    <img
                                        src={`https://backend.whitefeatherfashions.com/${item.product.images[0]}`}
                                        alt={item.product.name}
                                        className="cart-item-image"
                                    />
                                    <div className="cart-item-details">
                                        <p>{item.product.name}</p>

                                        <p>Price: Rs.{item.price}</p>
                                    </div>

                                    <button
                                        onClick={() => handleRemoveItem(item.product._id)}

                                    >
                                        <img src={deleteicon} alt="cart" />
                                    </button>
                                    <button
                                        onClick={() => handleAddToCart(item.product._id)}

                                    >
                                        <img src={carticon} alt="cart" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>



                {/* Return to Shop Button */}
                <div className="cart-footer">
                    <button onClick={() => window.location.href = "/shop"} className="return-btn">Return to Shop</button>
                </div>
            </div>
        </div>
    );
};




export default WishList;
