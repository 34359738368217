import React, { useState } from "react";
import './ProdutDetail.css';
import ProductInner from "../../Components/ProductInner/ProductInner";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/FooterSection/FooterSection";
import RelatedProducts from "../../Components/RelatedProducts/RelatedProducts";


const ProductDetail = () => {
    const [productCategory, setProductCategory] = useState('')

    const handleProductCategory = (category) => {
        setProductCategory(category)
    }
    return(
        <>
            <Navbar/>
            <ProductInner onCategoryChange = {handleProductCategory}/>
            <RelatedProducts category = {productCategory}/>
            <Footer/>
        </>
    )
}

export default ProductDetail