import React, { useState, useEffect } from "react";
import './ProductSec.css';
import cartWhite from '../../Assets/images/cart-white.svg';
import api from "../../API/api";
import { useNavigate,useLocation } from "react-router-dom";

const ProductSec = () => {
    const [products, setProducts] = useState([]);
    const [priceRange, setPriceRange] = useState({ min: 500, max: 10000 });
    const [sortOption, setSortOption] = useState('menu_order'); // Default sorting option
    const location = useLocation();
    const navigate = useNavigate()
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const category = new URLSearchParams(location.search).get('category'); // Get category from query params
                const response = await api.get('/products/list', {
                    params: {
                        category,
                        minPrice: priceRange.min,
                        maxPrice: priceRange.max,
                        sort: sortOption,
                    },
                });
                setProducts(response.data.products); // Update state with fetched products
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [location.search, priceRange, sortOption]);

    const handleAddToCart = async (productId) => {
        try {
            // Assuming the quantity is 1 for simplicity
            const quantity = 1;
            const response = await api.post('/cart/add', { productId, quantity });

            if (response.status === 200) {
                window.alert('Product added to cart');
            }
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
    };

    const handlePriceChange = (e, type) => {
        const newValue = e.target.value;
        setPriceRange((prevState) => ({
            ...prevState,
            [type]: newValue,
        }));
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };
    const viewSingleProduct = async (id) => {
        navigate(`/productDetails/${id}`)
    }

    return (
        <div className="product-sec">
            <div className="wrapper">
                <div className="product-row">
                    <div className="product-col1">
                        <div className="product-header">
                            <div className="product-count">
                                <p>Showing {products.length} results</p>
                            </div>
                            <div className="product-sorting">
                                <select
                                    name="orderby"
                                    className="orderby"
                                    value={sortOption}
                                    onChange={handleSortChange}
                                    aria-label="Shop order"
                                >
                                    <option value="menu_order">Default sorting</option>
                                    {/* <option value="popularity">Sort by popularity</option> */}
                                    {/* <option value="rating">Sort by average rating</option> */}
                                    <option value="date">Sort by latest</option>
                                    <option value="price">Sort by price: low to high</option>
                                    <option value="price-desc">Sort by price: high to low</option>
                                </select>
                            </div>
                        </div>
                        <div className="product-row1">
                            {products.map((product) => {
                                const imageUrl =
                                    product.images && product.images.length > 0
                                        ? `https://backend.whitefeatherfashions.com/${product.images[0].replace(/\\/g, '/')}`
                                        : 'https://via.placeholder.com/150';
                                return (
                                    <div className="product-box" key={product.id} >
                                        <div className="product-box-top">
                                            <div className="product-img" onClick={() => viewSingleProduct(product._id)}>
                                                <img src={imageUrl} alt={product.name} />
                                            </div>
                                            <div className="cart-btn-wrapp">
                                                <button
                                                    className="cart-btn"
                                                    onClick={() => handleAddToCart(product._id)}
                                                >
                                                    <img src={cartWhite} alt="Add to Cart" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="product-detail">
                                            <h3 onClick={() => viewSingleProduct(product._id)}>{product.name}</h3>
                                            <p>{product.productCode}</p>
                                            <span className="price" onClick={() => viewSingleProduct(product._id)}>AED {product.price}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="product-col2">
                        <div className="price-fliter">
                            <h3>Filter by pricing</h3>
                            <div className="price-field">
                                <input
                                    type="range"
                                    min="500"
                                    max="10000"
                                    value={priceRange.min}
                                    onChange={(e) => handlePriceChange(e, 'min')}
                                    id="lower"
                                />
                                <input
                                    type="range"
                                    min="500"
                                    max="10000"
                                    value={priceRange.max}
                                    onChange={(e) => handlePriceChange(e, 'max')}
                                    id="upper"
                                />
                                <span className="price1">AED {priceRange.min}</span>
                                <span className="price2">AED {priceRange.max}</span>
                            </div>
                            {/* <button className="apply-btn" onClick={() => setPriceRange(priceRange)}>Apply</button> */}
                        </div>
                        <div className="categories">
                            <h3>Categories</h3>
                            <ul>
                                <li><a href="/product?category=Saree">Saree</a></li>
                                <li><a href="/product?category=Kurti">Kurti</a></li>
                                <li><a href="/product?category=Salwar Set">Salwar Set</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductSec;
