import React from 'react'
import {Navigate, Outlet  } from 'react-router-dom'

const userLoggedIn = () => {
    const user = localStorage.getItem('authToken')
  return (
   user ? <Outlet/> : <Navigate to='/register-login'/>
  )
}

export default userLoggedIn