import React from 'react';
import './FooterSection.css';
import logo from '../../Assets/images/logo.svg';
import instaGold from '../../Assets/images/instagram-brands-gold.svg';
import instaWhite from '../../Assets/images/instagram-brands-white.svg';
import fbgold from '../../Assets/images/facebook-brands-gold.svg';
import fbWhite from '../../Assets/images/facebook-brands-white.svg';
import tiktokgold from '../../Assets/images/tiktok-brands-gold.svg';
import tiktokwhite from '../../Assets/images/tiktok-brands-white.svg';
import locgold from '../../Assets/images/location-dot-gold.svg';
import locwhite from '../../Assets/images/location-dot-white.svg';
import phonegold from '../../Assets/images/phone-gold.svg';
import phoneWhite from '../../Assets/images/phone-white.svg';
import mailgold from '../../Assets/images/envelope-gold.svg';
import mailWhite from '../../Assets/images/envelope-white.svg';
import card1 from '../../Assets/images/mastercard.svg';
import card2 from '../../Assets/images/visa-card.svg';

const Footer = () => {
    return (
        <div className='footer-sec'>
            <div className='wrapper'>
                <div className='footer-row'>
                    <div className='footer-col1'>
                        <div className='footer-logo'>
                            <img src={logo} alt="" />
                        </div>
                        <div className='footer-social'>
                            <ul>
                                <li><a href="https://www.instagram.com/white.feather.fashion/"><span className='icon'><img src={instaGold} alt="" /> <span className='hover-icon'><img src={instaWhite} alt="" /></span></span></a></li>
                                <li><a href="https://www.facebook.com/Whitefeatherabudhabi"><span className='icon'><img src={fbgold} alt="" /> <span className='hover-icon'><img src={fbWhite} alt="" /></span></span></a></li>
                                <li><a href=""><span className='icon'><img src={tiktokgold} alt="" /> <span className='hover-icon'><img src={tiktokwhite} alt="" /></span></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='footer-col3 footer-links'>
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="/index">Home</a></li>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/shop">Shop</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className='footer-col4 footer-links'>
                        <h3>Category</h3>
                        <ul>
                            <li><a href="/product">Saree</a></li>
                            <li><a href="/product">Kurtis</a></li>
                            <li><a href="/product">Salwar Set</a></li>
                        </ul>
                    </div>
                    <div className='footer-col4 footer-links'>
                        <h3>Policy</h3>
                        <ul>
                            <li><a href="">Privacy Policy</a></li>
                            <li><a href="">Terms of Service</a></li>
                            <li><a href="">Shipping Policy</a></li>
                            <li><a href="">Return Policy</a></li>
                        </ul>
                    </div>
                    <div className='footer-col5 footer-links'>
                        <h3>Let’s Talk</h3>
                        <ul>
                            <li><a href=""><span className='icon'><img src={phonegold} alt="" /><span className='hover-icon'><img src={phoneWhite} alt="" /></span></span> +968 96123469 ( Oman )  <br />
 +971 506720980 ( UAE )</a></li>

                            <li><a href=""><span className='icon'><img src={mailgold} alt="" /><span className='hover-icon'><img src={mailWhite} alt="" /></span></span> info@whitefeatherfashions.com</a></li>
                            <li><a href=""><span className='icon'><img src={locgold} alt="" /><span className='hover-icon'><img src={locwhite} alt="" /></span></span> Abu Dhabi</a></li>

                        </ul>
                    </div>
                    <div className='footer-col6 cards-sec'>
                        <h4>We Accept</h4>
                        <div className='card'>
                            <img src={card1} alt="" />
                        </div>
                        <div className='card'>
                            <img src={card2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-row1'>
                <div className="wrapper">
                    <p>Copyright © 2024 White Feather. All rights reserved.</p>
                    <p>Designed and Developed by - <a href="https://envinto.com/" target="_blank" rel="noopener noreferrer">ENVINTO</a></p>
                </div>
            </div>
        </div>
    )
}

export default Footer