import { useEffect, useState } from 'react';
import './AboutsSection.css';
import aboutsImage from '../../Assets/images/abouts-img.png';
import aboutShape from '../../Assets/images/about-shape.png';
import api from '../../API/api';

const AboutsSection = () => {
      const [topTitle, setTopTitle] = useState('');
      const [topDescription, setTopDescription] = useState('');
      const [topImage, setTopImage] = useState(null);
    
      const [bottomTitle, setBottomTitle] = useState('');
      const [bottomDescription, setBottomDescription] = useState('');
      const [bottomImage, setBottomImage] = useState(null);
    useEffect(() => {
        const fetchHomeBanner = async () => {
          try {
            const response = await api.get("/admin/aboutbanner");
            const { topSection,
                bottomSection
            } = response.data.banner;
            console.log('data at about top:',response.data);
            
            setTopTitle(topSection.title || '')
            setTopDescription(topSection.description || '')
            setTopImage(topSection.image || null)
            setBottomTitle(bottomSection.title || '')
            setBottomDescription(bottomSection.description || '')
            setBottomImage(bottomSection.image || null)
    
          } catch (error) {
            console.error("Error fetching product banner:", error);
          }
        };
    
        fetchHomeBanner();
      }, []);
    return(
        <div className="abouts-wrapp">
            <div className='abouts-col1'>
                <div className='abouts-img'>
                    <img src={`https://backend.whitefeatherfashions.com/${bottomImage}`} alt="" />
                </div>
                <span className='shape'>
                    <img src={aboutShape} alt="" />
                </span>
            </div>
            <div className='abouts-col2'>
                <div className='abouts-conten'>
                    <h2>{bottomTitle}</h2>
                    <p>{bottomDescription}</p>
                </div>
            </div>
        </div>
    )
}

export default AboutsSection