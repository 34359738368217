"use client"

import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import api from "../../API/api"
import "./InnerBanner.css"

// Import default banner images for each category
import defaultBanner from "../../Assets/images/inner-img.jpeg"
import sareeDefaultBanner from "../../Assets/images/saree.webp"
import kurtiDefaultBanner from "../../Assets/images/kurtidefault.jpg"
import salwarDefaultBanner from "../../Assets/images/salwarsetdefault.png"

const InnerBanner = () => {
  const [title, setTitle] = useState("")
  const [image, setImage] = useState(null)
  const [localImage, setLocalImage] = useState(defaultBanner)
  const location = useLocation()

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        // Get category from URL query parameters
        const category = new URLSearchParams(location.search).get("category")

        // Set default local image based on category
        if (category) {
          switch (category.toLowerCase()) {
            case "saree":
              setLocalImage(sareeDefaultBanner)
              break
            case "kurti":
              setLocalImage(kurtiDefaultBanner)
              break
            case "salwar set":
              setLocalImage(salwarDefaultBanner)
              break
            default:
              setLocalImage(defaultBanner)
          }

          // Try to fetch category-specific banner from API
          const response = await api.get("/admin/categorybanner", {
            params: { category },
          })

          const { title: categoryTitle, image: categoryImage } = response.data
          setTitle(categoryTitle || category || "")
          setImage(categoryImage || null)
        } else {
          // Fallback to home banner if no category is specified
          setLocalImage(defaultBanner)
          const response = await api.get("/admin/homebanner")
          const { title: homeTitle, image: homeImage } = response.data
          setTitle(homeTitle || "")
          setImage(homeImage || null)
        }
      } catch (error) {
        console.error("Error fetching banner:", error)
        // Keep the default local image already set
        const category = new URLSearchParams(location.search).get("category")
        setTitle(category || "")
      }
    }

    fetchBanner()
  }, [location.search])

  // Determine which image to display
  const displayImage = image ? `https://backend.whitefeatherfashions.com/${image}` : localImage

  return (
    <div className="inner-banner">
      <div className="inner-bg">
        <img
          src={displayImage || "/placeholder.svg"}
          alt={title || "Banner"}
          onError={(e) => {
            // If API image fails to load, fall back to local image
            e.target.src = localImage
          }}
        />
      </div>
      <div className="inner-conten">
        <div className="wrapper">
          <h2>{title}</h2>
        </div>
      </div>
    </div>
  )
}

export default InnerBanner

